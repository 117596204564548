import React from 'react';
import '../App.css';
import { Box, Grid } from '@mui/material';
import CardLine from '../components/CardLine';
import CardHeader from '../components/CardHeader';

const GeneralTab = (props) => {
  

    return (
      <Box >
        <Grid container>
          <Grid item xs={12}>
            <CardLine name='Amount of machines' label='for same process' id='general1' calculate={props.calculate} unit='pcs' startValue='18' tooltip='The total of all similiar printing machines. E.g. all FDM machines.'/>
          </Grid>
          <Grid item xs={12}>
            <CardLine name='Operating hours per machine'label='per month' id='general2' calculate={props.calculate} unit='hours' startValue='396' tooltip='How many hours do your machines/printers work on average in one month?' />
          </Grid>
          <Grid item xs={12}>
            <CardLine name='Total Operating hours of staff'label='per month' id='general3' calculate={props.calculate} unit='hours' startValue='196' tooltip='How many total hours do your employees spend on 3D printing related activites per month?'/>
          </Grid>
        </Grid>
      </Box>  
    );
}
 
export default GeneralTab ;