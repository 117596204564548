import React, {useEffect, useRef, useState} from 'react';
import { Grid, Paper, Card, Box, InputAdornment, Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export default function CardLine(props) {

    const [tfValue, setTfValue] = useState();

    //run useEffect once only
    useEffect(() => {
        const initialValues = () => {
            //NEED TO TAKE ALL IDs, 
            if (localStorage.getItem(props.id) !== null) {
                setTfValue(localStorage.getItem(props.id));            
            }
            //need to load the startValue into local storange, otherwise you can see the start value but it won't be taken for calc
            else {
                localStorage.setItem(props.id, props.startValue);
            }
        }
        initialValues();

    }, []);
    
    //what happens when I enter (change) or tab out
    const onTrigger = (e) => {
        setTfValue(e.target.valueAsNumber);
        localStorage.setItem(props.id, e.target.valueAsNumber);
        // console.log(localStorage.getItem(props.id));
        props.calculate();
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
    }));

  return (
    <Box>
        <Grid container sx={{mx: 'auto', my: '6px'}}
        >
            <Grid item xs={6} sx={{display: 'flex',alignItems: 'center'}}>
                <label htmlFor="">{props.name}</label>
            </Grid>
            <Grid item xs={5}>
                <TextField 
                    id="standard-basic" 
                    variant="standard" 
                    onBlur={onTrigger} 
                    value={tfValue}
                    onChange={onTrigger}
                    type="number"
                    defaultValue={props.startValue}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" sx={{ width: '50px'}}>{props.unit}</InputAdornment>,
                      }} 
                />
            </Grid>
            <Grid item xs={1}>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">{props.tooltip}</Typography>
                        </React.Fragment>
                    }
                >
                <Button><HelpOutlineIcon /></Button>
                </HtmlTooltip> 
            </Grid>
        </Grid>
    </Box>
  )
}
