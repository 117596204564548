import React, {useEffect, useRef, useState} from 'react';
import { Grid, Paper, Card, Box, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';

export default function CardResult(props) {

    const [tfValue, setTfValue] = useState();

    //run useEffect once only
    // useEffect(() => {
    //     if (localStorage.getItem(props.id) !== null) {
    //         console.log(localStorage.getItem(props.id))
    //         setTfValue(localStorage.getItem(props.id));            
    //     }
    // }, []);
    
    //what happens when I enter (change) or tab out
    const onTrigger = (e) => {
        setTfValue(e.target.value);
        localStorage.setItem(props.id, e.target.value);
    }



  return (
    <Box>
        <Grid container sx={{my: '6px'}}>
            <Grid item xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                <label htmlFor="">{props.name}</label>
            </Grid>
            <Grid item xs={5}>
                <TextField
                    id="standard-basic" 
                    variant="standard" 
                    onBlur={onTrigger} 
                    value={props.result.toFixed(2)} 
                    onChange={onTrigger}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" sx={{ width: '50px'}}>{props.unit}</InputAdornment>,
                      }}
                    sx={{backgroundColor: '#bbdefb', color: 'black'}}
                />
            </Grid>
        </Grid>
    </Box>
  )
}
