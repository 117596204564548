import React from 'react';
import '../App.css';
import { Box, Grid } from '@mui/material';
import CardLine from '../components/CardLine';
import CardHeader from '../components/CardHeader';
import CardResult from '../components/CardResult';

const OverheadTab = (props) => {
 
    return (
      <Box >
        <Grid container>
          <Grid item xs={12}>
            <CardLine name='Rent per year'label='Standard' id='overhead1' calculate={props.calculate} unit='$' startValue='12000' tooltip='Total rent that you pay. You might have to calculate the rent for the area that your machines are standing, if this is just a part of your business.'/>
          </Grid>
          <Grid item xs={12}>
            <CardLine name='Staff in printing'label='cost in $' id='overhead2' calculate={props.calculate} unit='$' startValue='100000' tooltip='The annual expense for staff that is working in your printing department or is related to it.'/>
          </Grid>
          <Grid item xs={12}>
            <CardLine name='Software licenses'label='in $' id='overhead3' calculate={props.calculate} unit='$' startValue='2500' tooltip='The annual expense for licenses such as splicing software, CAD software, etc.'/>
          </Grid>
          <Grid item xs={12}>
            <CardLine name='Other annual costs'label='in $' id='overhead4' calculate={props.calculate} unit='$' startValue='500' tooltip='Other annual costs that are related to 3D printing.'/>
          </Grid>
          <Grid item xs={12}S>
            <CardResult name='Overhead per machine'label='' result={props.result} unit='$/h'/>
          </Grid>
        </Grid>
      </Box>
    );
}
 
export default OverheadTab ;