import '../App.css';
import React from 'react';
import { Box, Grid } from '@mui/material';
import CardLine from '../components/CardLine';
import CardHeader from '../components/CardHeader';
import CardResult from '../components/CardResult';

const MachineTab = (props) => {

    return ( 
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <CardLine name='Total machine cost'label='total' id='machine1' calculate={props.calculate} unit='$' startValue='90000' tooltip='Total costs of the machine investment. This related to the amount of machines you entered under "General".'/>
          </Grid>
          <Grid item xs={12}>
            <CardLine name='avg. lifetime of 1 machine'label='in h' id='machine2' calculate={props.calculate} unit='h' startValue='8000' tooltip='The average lifetime of a machine. The manufacturer usually gives you a value here.'/>
          </Grid>
          <Grid item xs={12}>
            <CardResult name='depreciation per machine'label='per h' id='machine3' result={props.resultDepreciation} unit='$/h'/>
          </Grid>
          <Grid item xs={12}>
            <CardLine name='operating costs'label='per year' id='machine4' calculate={props.calculate} unit='$' startValue='4500' tooltip='Annual operating costs for your machines. This includes electricity, oil, maintenance, etc.'/>
          </Grid>
        </Grid>
      </Box>
    );
}
 
export default MachineTab ;