import './App.css';
import React from 'react';
import Interface from './Interface';
import { Grid, Paper, Card, Box } from '@mui/material';


function App() {
  return (
    <Box>
      <Grid 
        container 
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={12} sx={{marginBottom: '30px'}}>
          <header className="App-header">
            <h1>Hourly Machine Rate Calculator</h1>
          </header>
        </Grid>
        <Grid item xs={12}>
          <Interface></Interface> 
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
